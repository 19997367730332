<template>
    <div class="copy-dashboard">
        <div class="form-control instructions">Copy widget to dashboard:</div>
        <div>
            <base-input
                    elementType="select"
                    v-model="selectedDashboard">
                <option
                        v-for="dashboard in dashboards"
                        :key="'dashboard-'+dashboard.id"
                        :value="dashboard.id"
                >{{ dashboard.name }}
                </option>
            </base-input>
        </div>
        <div class="form-control" style="margin-left: 15px;">
            <base-button
                    style="width: 48px;"
                    mode="blue"
                    :link="false"
                    @click.native="copyWidget"
            >
                <font-awesome-icon :icon="['fas', 'copy']"/>
            </base-button>
        </div>
    </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';

const dashboards = createNamespacedHelpers('dashboard');

export default {
    created() {
        if (this.dashboards) {
            this.selectedDashboard = this.dashboards[0].id;
        }
    },
    data() {
        return {
            selectedDashboard: null
        }
    },
    computed: {
        ...dashboards.mapGetters(['dashboards', 'layouts']),
    },
    methods: {
        copyWidget() {
            console.log(this.selectedDashboard);
            this.$emit("copy-widget", this.selectedDashboard)
        }
    }
}
</script>

<style lang="scss" scoped>
.copy-dashboard {
  background-color: rgba($color-01, 0.01);
  border: 1px solid $color-067;
  @include respond(lg) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.instructions {
  line-height: 35px;
  @include respond(lg) {
    text-align: center;
  }
}
</style>
